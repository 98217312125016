import { render, staticRenderFns } from "./RecentlyViewed1.vue?vue&type=template&id=0e618a18"
import script from "./RecentlyViewed1.vue?vue&type=script&lang=js"
export * from "./RecentlyViewed1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCardInfo: require('/codebuild/output/src3469357009/src/client/components/productCard/ProductCardInfo.vue').default})
